<script>
  let audio;

  const bkbHeaa = () => audio.play();
</script>

<button on:click={bkbHeaa}>⛪</button>
<h1>bkb.church</h1>

<audio bind:this={audio} src="assets/BKBHEAA.mp3" />

<style>
  button {
    font-size: 4em;
    margin: 0 auto;
  }
</style>