<script>
  import Bkb from "./BKB.svelte";
</script>

<main>
	<Bkb/>
</main>

<style>
	main {
		text-align: center;
		padding: 4em;
	}
</style>